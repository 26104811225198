import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import Button from 'components/button';
import ProductCard from 'components/product-card';
import ProductModal from 'components/product-modal';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const ProductsCardContent = ({ callToAction, products, onButtonClick }) => {
  const [selectedProductDetails, setSelectedProductDetails] = useState(null);
  const [showProductModal, setShowProductModal] = useState(false);

  const handleProductCardClick = (productDetails) => {
    setShowProductModal(true);
    setSelectedProductDetails(productDetails);
  };

  const handleProductModalClose = () => {
    setShowProductModal(false);
  };

  return (
    <div className={styles.productsCardContent}>
      {Array.isArray(products) && !!products.length ?
        (
          <div className={styles.productsCardContent__products}>
            {products.map((product, index) => (
              <ProductCard
                key={index}
                className={styles.productsCardContent__productCard}
                priceSize="s"
                productDetails={product}
                titleSize="s"
                onClick={() => handleProductCardClick(product)}
              />
            ))}
            {(!!callToAction?.href || !!onButtonClick) && !!callToAction?.icon && (
              <Button
                className={styles.productsCardContent__gridButton}
                href={callToAction?.href}
                icon={callToAction.icon}
                outline
                textWeight="regular"
                theme="grey"
                onClick={onButtonClick}
              />
            )}
          </div>
        ) :
        (!!callToAction?.href || !!onButtonClick) && (!!callToAction?.text || !!callToAction?.icon) && (
          <Button
            href={callToAction?.href}
            icon={callToAction?.icon}
            iconPosition={callToAction?.iconPosition}
            iconSize="s"
            outline
            size="xs"
            text={callToAction?.text}
            textWeight="regular"
            theme="grey"
            onClick={onButtonClick}
          />
        )}
      <ProductModal
        productDetails={selectedProductDetails}
        show={showProductModal}
        onClose={handleProductModalClose}
      />
    </div>
  );
};

ProductsCardContent.propTypes = {
  callToAction: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string
  }),
  onButtonClick: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  }))
};

ProductsCardContent.defaultProps = {
  callToAction: null,
  onButtonClick: null,
  products: null
};

export default ProductsCardContent;
