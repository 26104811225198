import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';
import Loader from 'components/loader';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Button = forwardRef(({
  actionableInputId,
  block,
  className,
  contentAlignment,
  contentClassName,
  disabled,
  external,
  hoverCursor,
  href,
  icon,
  iconClassName,
  iconPosition,
  iconSize,
  leftSlot,
  loading,
  noBackground,
  onClick,
  outline,
  paddingSize,
  rightSlot,
  size,
  tabIndex,
  text,
  textWeight,
  theme,
  type,
  underlineOnHover,
  ...buttonProps
}, ref) => {
  let themeStyleClassName = null;

  switch (theme) {
    case 'google':
      themeStyleClassName = ` ${styles.button_google}`;
      break;
    case 'grey':
      themeStyleClassName = ` ${styles.button_grey}`;
      break;
    case 'secondary':
      themeStyleClassName = ` ${styles.button_secondary}`;
      break;
    case 'unstyled':
      themeStyleClassName = '';
      break;
    case 'primary':
    default:
      themeStyleClassName = ` ${styles.button_primary}`;
  }

  const buttonIsNotClickable = disabled || loading;

  const classNames = `${
    styles.button}${
    block ? ' d-block' : ''}${
    disabled ? ` ${styles.button_disabled}` : ''}${
    hoverCursor === 'grab' ? ` ${styles.button_grabCursor}` : ''}${
    loading ? ` ${styles.button_loading}` : ''}${
    noBackground ? ` ${styles.button_noBackground}` : ''}${
    outline ? ` ${styles.button_outline}` : ''}${
    paddingSize === 'none' ? ` ${styles.button_noPadding}` : ''}${
    size === 's' ? ` ${styles.button_small}` : ''}${
    size === 'xs' ? ` ${styles.button_extraSmall}` : ''}${
    !text ? ` ${styles.button_noText}` : ''}${
    underlineOnHover ? ` ${styles.button_underlineOnHover}` : ''}${
    themeStyleClassName}${
    className ? ` ${className}` : ''}`;

  const contentClassNames = `d-flex align-items-center${
    contentAlignment === 'left' ? ` ${styles.button_contentLeft}` : ''}${
    contentAlignment === 'right' ? ` ${styles.button_contentRight}` : ''}${
    contentClassName ? ` ${contentClassName}` : ''}`;

  const iconClassNames = `${styles.button__icon}${
    iconPosition === 'left' ? ` ${styles.button__icon_onTheLeft}` : ''}${
    iconPosition === 'right' ? ` ${styles.button__icon_onTheRight} order-2` : ''}${
    iconSize === 'l' ? ` ${styles.button__icon_big}` : ''}${
    iconSize === 's' ? ` ${styles.button__icon_small}` : ''}${
    loading && !text ? ' d-none' : ''}${
    !text ? ` ${styles.button__icon_noMargin}` : ''}${
    iconClassName ? ` ${iconClassName}` : ''}`;

  const loaderClassNames = `${styles.button__loader}${
    text && icon && iconPosition === 'left' ? ` ${styles.button__loader_marginLeft} order-3` : ''}${
    text && icon && iconPosition === 'right' ? ` ${styles.button__loader_marginRight}` : ''}${
    text && !icon ? ` ${styles.button__loader_marginLeft} order-2` : ''}`;

  const handleButtonClick = (event) => {
    if (!buttonIsNotClickable) onClick(event);
  };

  const content = actionableInputId ?
    // This is necessary if we want to allow our buttons to function as labels for inputs
    // This solution was found in Stack Overflow: https://stackoverflow.com/a/39479328/21237120
    (
      <label
        className={contentClassNames}
        htmlFor={actionableInputId}
      >
        {!!leftSlot && leftSlot}
        {!!loading && (
          <Loader
            className={loaderClassNames || null}
            color={theme !== 'primary' ? 'orange' : 'white'}
          />
        )}
        {!!icon && (
          <Icon
            className={iconClassNames || null}
            name={icon}
          />
        )}
        {!!text && (
          <span className={textWeight ? ` ${styles[`button__text_${textWeight}`]}` : null}>
            {text}
          </span>
        )}
        {!!rightSlot && rightSlot}
      </label>
    ) :
    (
      <div className={contentClassNames}>
        {!!leftSlot && leftSlot}
        {!!loading && (
          <Loader
            className={loaderClassNames || null}
            color={theme !== 'primary' ? 'orange' : 'white'}
          />
        )}
        {!!icon && (
          <Icon
            className={iconClassNames || null}
            name={icon}
          />
        )}
        {!!text && (
          <span className={textWeight ? ` ${styles[`button__text_${textWeight}`]}` : null}>
            {text}
          </span>
        )}
        {!!rightSlot && rightSlot}
      </div>
    );

  if (href) {
    const linkProps = {
      href,
      role: 'button',
      tabIndex,
      ...(disabled && { tabIndex: -1 }),
      ...buttonProps
    };

    return external ?
      (
        <a
          ref={ref}
          className={classNames || null}
          rel="noopener noreferrer"
          target="_blank"
          {...linkProps}
        >
          {content}
        </a>
      ) :
      (
        <Link
          legacyBehavior
          {...linkProps}
        >
          <a
            ref={ref}
            className={`${styles.button_isALink}${classNames ? ` ${classNames}` : ''}`}
            href={href}
            role="button"
            tabIndex={tabIndex}
            {...linkProps}
          >
            {content}
          </a>
        </Link>
      );
  }

  return (
    <button
      ref={ref}
      {...buttonProps}
      className={classNames || null}
      disabled={buttonIsNotClickable}
      tabIndex={tabIndex}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={handleButtonClick}
    >
      {content}
    </button>
  );
});

Button.propTypes = {
  actionableInputId: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  contentAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  contentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  hoverCursor: PropTypes.oneOf(['pointer', 'grab']),
  href: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconSize: PropTypes.oneOf(['default', 's', 'l']),
  leftSlot: PropTypes.element,
  loading: PropTypes.bool,
  noBackground: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  paddingSize: PropTypes.oneOf(['default', 'none']),
  rightSlot: PropTypes.element,
  size: PropTypes.oneOf(['default', 'xs', 's']),
  tabIndex: PropTypes.number,
  text: PropTypes.string,
  textWeight: PropTypes.oneOf([
    'thin',
    'extralight',
    'light',
    'regular',
    'medium',
    'semibold',
    'bold',
    'extrabold',
    'black'
  ]),
  theme: PropTypes.oneOf(['google', 'grey', 'primary', 'secondary', 'unstyled']),
  type: PropTypes.string,
  underlineOnHover: PropTypes.bool
};

Button.defaultProps = {
  actionableInputId: null,
  block: false,
  className: null,
  contentAlignment: 'center',
  contentClassName: null,
  disabled: false,
  external: false,
  hoverCursor: 'pointer',
  href: null,
  icon: null,
  iconClassName: null,
  iconPosition: 'right',
  iconSize: 'default',
  leftSlot: null,
  loading: false,
  noBackground: false,
  onClick: () => {},
  outline: false,
  paddingSize: 'default',
  rightSlot: null,
  size: 'default',
  tabIndex: null,
  text: null,
  textWeight: 'semibold',
  theme: 'primary',
  type: 'button',
  underlineOnHover: false
};

Button.displayName = 'Button';

export default Button;
