import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const LinkBox = ({ href, completed, isTodo, text }) => (
  <Link
    href={href}
    legacyBehavior
  >
    <a
      className={`${styles.linkBox}${completed ? ` ${styles.linkBox_completed}` : ''}`}
      tabIndex={completed ? -1 : 0}
    >
      <div className={styles.linkBox__iconAndText}>
        {isTodo && (
          <div className={styles.linkBox__iconWrapper}>
            <Icon
              className={styles.linkBox__icon}
              name={completed ? 'check-circle--red' : 'close-circle'}
            />
          </div>
        )}
        <div className={styles.linkBox__text}>
          {text}
        </div>
      </div>
      <Icon
        className={styles.linkBox__buttonIcon}
        name="arrow-right"
      />
    </a>
  </Link>
);

LinkBox.propTypes = {
  completed: PropTypes.bool,
  href: PropTypes.string.isRequired,
  isTodo: PropTypes.bool,
  text: PropTypes.string.isRequired
};

LinkBox.defaultProps = {
  completed: false,
  isTodo: false
};

export default LinkBox;
