
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Store
import {
  fetchRestaurants,
  selectCurrentRestaurant
} from 'store/slices/restaurantsSlice';

import {
  fetchMenus,
  selectCurrentMenu
} from 'store/slices/menusSlice';

import {
  fetchProducts,
  selectProducts
} from 'store/slices/productsSlice';

import { useDispatch, useSelector } from 'react-redux';

// Utils
import bemify from 'utils/bemify';

// Components
import Card from 'components/card';

// ? This is commented because it was decided that it should be hidden for now
// import FrequentlyAskedQuestions from 'components/frequently-asked-questions';

import LinkBox from 'components/link-box';
import Loader from 'components/loader';

// ? This is commented because it was decided that it should be hidden for now
// import TutorialCard from 'components/tutorial-card';

// Dashboard components
import MenusCardContent from 'components/dashboard/menus-card-content';

// ? This is commented because it was decided that it should be hidden for now
// import OverviewCard from 'components/dashboard/overview-card';

import ProductsCardContent from 'components/dashboard/products-card-content';

// Templates
import PageWithSidebar from 'templates/page-with-sidebar';

// Styles
import stylesModule from 'styles/pages/dashboard.module.scss';


const styles = bemify(stylesModule);

const Dashboard = () => {
  const { t } = useTranslationWithFallback('dashboard');

  const dispatch = useDispatch();

  const [isRunningInitialFetch, setIsRunningInitialFetch] = useState(true);

  // Selectors
  const menu = useSelector(selectCurrentMenu);
  const products = useSelector(selectProducts);
  const restaurant = useSelector(selectCurrentRestaurant);

  // ? This is commented because it was decided that it should be hidden for now
  // const [showOverviewCard, setShowOverviewCard] = useState(false);
  // ? This is commented to be used as a reference on how to display the menu preview in the future
  // const [showRestaurantMenuPreview, setShowRestaurantMenuPreview] = useState(false);

  // Computed variables
  const isLoading = useMemo(
    () => isRunningInitialFetch,
    [isRunningInitialFetch]
  );

  const isPerformingInitialFetch = useMemo(
    () => isRunningInitialFetch && (!Array.isArray(products) || !menu || !restaurant),
    [isRunningInitialFetch, products, menu, restaurant]
  );

  // ? This is commented because it was decided that it should be hidden for now
  // const overviewCard = useMemo(() => {
  //   if (!restaurant) return null;

  //   let title = t('restaurant_overview_card.title');

  //   if (restaurant?.name) title = restaurant.name;

  //   return {
  //     callToAction: {
  //       href: '/edit-menu',
  //       text: t('restaurant_overview_card.call_to_action')
  //     },
  //     description: t('restaurant_overview_card.description'),
  //     image: {
  //       alt: t('restaurant_overview_card.image.alt'),
  //       src: '/images/dashboard/cards/restaurant-overview-card/menu-1.png'
  //     },
  //     title
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [restaurant]);

  const showMenusCard = useMemo(
    () => Array.isArray(menu?.menuSections) && !menu?.menuSections?.length,
    [menu]
  );

  const restaurantProductsCard = useMemo(() => {
    if (!restaurant) return null;

    let description = t('restaurant_products_card.description');

    if (Array.isArray(products) && products.length) {
      if (products.length > 20) description = t('restaurant_products_card.description_more_than_20_products');
      else if (products.length === 1) description = `${products.length} ${t('restaurant_products_card.description_singular_product')}`;
      else description = `${products.length} ${t('restaurant_products_card.description_many_products')}`;
    }

    return {
      callToAction: {
        href: '/products',
        icon: 'plus-circle',
        iconPosition: 'left',
        text: t('restaurant_products_card.call_to_action')
      },
      description,
      number: showMenusCard ? 2 : 1,
      products,
      title: t('restaurant_products_card.title')
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, showMenusCard]);

  const todoListCard = useMemo(() => {
    const restaurantHasProducts = !!(Array.isArray(products) && products.length);
    const menuHasSections = !!(Array.isArray(menu?.menuSections) && menu?.menuSections?.length);
    const restaurantHasNameAndCuisineType = !!(restaurant?.name && restaurant?.category);

    const updatedTodoItems = [
      {
        completed: restaurantHasNameAndCuisineType,
        href: '/restaurant-profile',
        text: t('todo_card.todo_items.add_restaurant_name_and_cuisine_type')
      },
      {
        completed: restaurantHasProducts,
        href: '/products',
        text: t('todo_card.todo_items.add_products')
      },
      {
        completed: menuHasSections,
        href: '/edit-menu',
        text: t('todo_card.todo_items.sort_menu')
      }
    ];

    return {
      description: t('todo_card.description'),
      title: t('todo_card.title'),
      todoItems: updatedTodoItems
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, menu, restaurant]);

  const todoItems = useMemo(() => {
    let updatedTodoItems = [];

    if (todoListCard && Array.isArray(todoListCard.todoItems) && todoListCard.todoItems.length) updatedTodoItems = todoListCard.todoItems;

    return updatedTodoItems;
  }, [todoListCard]);

  const todoListCompleted = useMemo(
    () => Array.isArray(todoItems) && !!todoItems.length && todoItems.every(item => item.completed),
    [todoItems]
  );

  // Constant variables
  const restaurantMenusCard = useMemo(() => {
    if (!restaurant) return null;

    return {
      callToAction: {
        href: '/edit-menu',
        icon: 'plus-circle',
        iconPosition: 'left',
        text: t('restaurant_menus_card.call_to_action')
      },
      description: t('restaurant_menus_card.description'),
      // ? This is commented to be used as a reference on how to display the menu preview in the future
      // menuImages: showRestaurantMenuPreview ?
      //   [
      //     {
      //       alt: t('restaurant_menus_card.menu_images.one.alt'),
      //       src: '/images/dashboard/cards/restaurant-menus-card/menu-1.png'
      //     }
      //   ] :
      //   null,
      number: showMenusCard ? 1 : null,
      title: t('restaurant_menus_card.title')
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant, showMenusCard]);

  // ? This is commented because it was decided that it should be hidden for now
  // const frequentlyAskedQuestionsCard = {
  //   callToAction: {
  //     href: '/',
  //     text: t('frequently_asked_questions_card.call_to_action')
  //   },
  //   description: t('frequently_asked_questions_card.description'),
  //   items: [
  //     {
  //       answer: t('frequently_asked_questions_card.items.one.answer'),
  //       href: '/',
  //       question: t('frequently_asked_questions_card.items.one.question')
  //     },
  //     {
  //       answer: t('frequently_asked_questions_card.items.two.answer'),
  //       href: '/',
  //       question: t('frequently_asked_questions_card.items.two.question')
  //     },
  //     {
  //       answer: t('frequently_asked_questions_card.items.three.answer'),
  //       href: '/',
  //       question: t('frequently_asked_questions_card.items.three.question')
  //     },
  //     {
  //       answer: t('frequently_asked_questions_card.items.four.answer'),
  //       href: '/',
  //       question: t('frequently_asked_questions_card.items.four.question')
  //     }
  //   ],
  //   title: t('frequently_asked_questions_card.title')
  // };

  // ? This is commented because it was decided that it should be hidden for now
  // const tutorialCard = {
  //   callToAction: {
  //     text: t('tutorial_card.call_to_action')
  //   },
  //   description: t('tutorial_card.description'),
  //   image: {
  //     alt: t('tutorial_card.image.alt'),
  //     src: '/images/dashboard/cards/tutorial-card/food.png'
  //   },
  //   title: t('tutorial_card.title')
  // };

  // ? This is commented to be used as a reference on how to display the menu preview in the future
  // // Handle for mocked state
  // // TODO: Remove after connecting to API
  // const handleAddRestaurantMenuButtonClick = () => {
  //   setShowRestaurantMenuPreview(true);
  // };

  // ? This is commented because it was decided that it should be hidden for now
  // const handleTutorialCardButtonClick = () => {
  //   setShowOverviewCard(true);
  // };

  const fetchInitial = useCallback(async () => {
    setIsRunningInitialFetch(true);
    const { payload } = await dispatch(fetchRestaurants());

    if (payload?.length) {
      const menuFetcher = () => dispatch(fetchMenus());
      const productsFetcher = () => dispatch(fetchProducts());

      const promises = [
        menuFetcher(),
        productsFetcher()
      ];

      await Promise.all(promises);
    }

    setIsRunningInitialFetch(false);
  }, [dispatch]);

  useEffect(() => {
    fetchInitial();
  }, [fetchInitial]);

  return (
    <PageWithSidebar>
      <div className={`${styles.dashboard}${isLoading ? ` ${styles.dashboard_locked}` : ''}`}>
        {isPerformingInitialFetch || (Array.isArray(todoItems) && !!todoItems.length && !todoListCompleted && isLoading) ?
          (
            <div className={styles.dashboard__loaderWrapper}>
              <Loader />
            </div>
          ) :
          (
            <div className={styles.dashboard__contentGrid}>
              <div className={styles.dashboard__contentGridLeftColumn}>
                {/* This is commented because it was decided that it should be hidden for now */}
                {/* {showOverviewCard ?
                    (
                      <OverviewCard
                        callToAction={overviewCard?.callToAction}
                        compactView={todoListCompleted}
                        description={overviewCard?.description}
                        image={overviewCard?.image}
                        title={overviewCard?.title}
                        onButtonClick={handleTutorialCardButtonClick}
                      />
                    ) :
                    (
                      <TutorialCard
                        callToAction={tutorialCard?.callToAction}
                        description={tutorialCard?.description}
                        image={tutorialCard?.image}
                        title={tutorialCard?.title}
                        onButtonClick={handleTutorialCardButtonClick}
                      />
                    )} */}
                {((showMenusCard && !!restaurantMenusCard) || !!restaurantProductsCard) && todoListCompleted && (
                  <Card paddingSize="s">
                    <div className={styles.dashboard__menuAndProductsCards}>
                      {(showMenusCard && !!restaurantMenusCard) && (
                        <Card
                          callToAction={restaurantMenusCard.callToAction}
                          description={restaurantMenusCard.description}
                          hasBorder
                          number={restaurantMenusCard.number}
                          paddingSize="s"
                          title={restaurantMenusCard.title}
                          titleSize="s"
                        >
                          <MenusCardContent
                            callToAction={restaurantMenusCard.callToAction}
                            menuImages={restaurantMenusCard.menuImages}
                          />
                        </Card>
                      )}
                      {!!restaurantProductsCard && (
                        <Card
                          callToAction={restaurantProductsCard.callToAction}
                          description={restaurantProductsCard.description}
                          hasBorder
                          number={!todoListCompleted ? restaurantProductsCard.number : null}
                          paddingSize="s"
                          title={restaurantProductsCard.title}
                          titleSize="s"
                        >
                          <ProductsCardContent
                            callToAction={restaurantProductsCard.callToAction}
                            products={restaurantProductsCard.products}
                          />
                        </Card>
                      )}
                    </div>
                  </Card>
                )}
                {Array.isArray(todoItems) && !!todoItems.length && !todoListCompleted && !isLoading && (
                  <Card
                    className={styles.dashboard__card}
                    description={todoListCard.description}
                    title={todoListCard.title}
                  >
                    <div className={styles.dashboard__todoItems}>
                      {todoItems.map((todo, index) => (
                        <LinkBox
                          key={index}
                          completed={todo.completed}
                          href={todo.href}
                          isTodo
                          text={todo.text}
                        />
                      ))}
                    </div>
                  </Card>
                )}
                {/* This is commented because it was decided that it should be hidden for now */}
                {/* {!!frequentlyAskedQuestionsCard && Array.isArray(frequentlyAskedQuestionsCard.items) && !!frequentlyAskedQuestionsCard.items.length && (
                    <Card
                      className={styles.dashboard__card}
                      description={frequentlyAskedQuestionsCard.description}
                      title={frequentlyAskedQuestionsCard.title}
                    >
                      <FrequentlyAskedQuestions
                        callToAction={frequentlyAskedQuestionsCard.callToAction}
                        items={frequentlyAskedQuestionsCard.items}
                      />
                    </Card>
                  )} */}
              </div>
              {/* ? This is commented because it was decided that the restaurant cards only show when the TODOs are completed.
              /** ? Because This elements were only showed in desktop (right column), we can now use the mobile elements
              /** ? and show them in both mobile and desktop. */}
              {/* {(!!restaurantMenusCard || !!restaurantProductsCard) && todoListCompleted && (
                <div className={`${styles.dashboard__contentGridRightColumn} d-none d-xl-block`}>
                  <Card paddingSize="s">
                    <div className={styles.dashboard__menuAndProductsCards}>
                      {!!restaurantMenusCard && (
                        <Card
                          callToAction={restaurantMenusCard.callToAction}
                          description={restaurantMenusCard.description}
                          hasBorder
                          number={restaurantMenusCard.number}
                          paddingSize="s"
                          title={restaurantMenusCard.title}
                          titleSize="s"
                        >
                          <MenusCardContent
                            callToAction={restaurantMenusCard.callToAction}
                            menuImages={restaurantMenusCard.menuImages}
                          />
                        </Card>
                      )}
                      {!!restaurantProductsCard && (
                        <Card
                          callToAction={restaurantProductsCard.callToAction}
                          description={restaurantProductsCard.description}
                          hasBorder
                          number={restaurantProductsCard.number}
                          paddingSize="s"
                          title={restaurantProductsCard.title}
                          titleSize="s"
                        >
                          <ProductsCardContent
                            callToAction={restaurantProductsCard.callToAction}
                            products={restaurantProductsCard.products}
                          />
                        </Card>
                      )}
                    </div>
                  </Card>
                </div>
              )} */}
            </div>
          )}
      </div>
    </PageWithSidebar>
  );
};

export default Dashboard;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  