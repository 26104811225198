import React from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import Button from 'components/button';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Card = ({
  callToAction,
  className,
  children,
  description,
  hasBorder,
  number,
  onButtonClick,
  paddingSize,
  title,
  titleSize
}) => (
  <section className={`${styles.card}${
    className ? ` ${className}` : ''}${
    hasBorder ? ` ${styles.card_border}` : ''}${
    paddingSize === 'none' ? ` ${styles.card_paddingNone}` : ''}${
    paddingSize === 's' ? ` ${styles.card_paddingSmall}` : ''}`}
  >
    {(!!title || !!description || !!number) && (
      <header className={styles.card__header}>
        <div>
          {(!!title || !!number) && (
            <div className={styles.card__numberAndTitle}>
              {!!number && (
                <div className={styles.card__number}>
                  {number}
                </div>
              )}
              {!!title && (
                <h6 className={`${styles.card__title}${titleSize === 's' ? ` ${styles.card__title_small}` : ''}`}>
                  {title}
                </h6>
              )}
            </div>
          )}
          {!!description && (
            <p className={styles.card__description}>
              {description}
            </p>
          )}
        </div>
        {(!!callToAction?.href || !!onButtonClick) && (
          <Button
            href={callToAction?.href}
            icon="arrow-right"
            noBackground
            paddingSize="none"
            textWeight="regular"
            theme="secondary"
            onClick={onButtonClick}
          />
        )}
      </header>
    )}
    {!!children && (
      <div className={`${styles.card__children}${(title || description || number) ? ` ${styles.card__children_withMarginTop}` : ''}`}>
        {children}
      </div>
    )}
  </section>
);

Card.propTypes = {
  callToAction: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    text: PropTypes.string
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  description: PropTypes.string,
  hasBorder: PropTypes.bool,
  number: PropTypes.number,
  onButtonClick: PropTypes.func,
  paddingSize: PropTypes.oneOf(['default', 'none', 's']),
  title: PropTypes.string,
  titleSize: PropTypes.oneOf(['default', 's'])
};

Card.defaultProps = {
  callToAction: null,
  children: null,
  className: null,
  description: null,
  hasBorder: false,
  number: null,
  onButtonClick: null,
  paddingSize: 'default',
  title: null,
  titleSize: 'default'
};

export default Card;
