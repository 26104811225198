import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/legacy/image';


const getBase64 = imagePath => fetch(imagePath)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  }));

const Image = (props) => {
  const [blurredImage, setBlurredImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { hasInitialAnimation, ...nextImageProps } = props;

  const { src } = nextImageProps;

  useEffect(() => {
    if (typeof src === 'string' && !hasInitialAnimation) {
      getBase64(src)
        .then((dataUrl) => {
          setBlurredImage(dataUrl);

          return dataUrl;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NextImage
      {...(blurredImage && !imageLoaded && {
        blurDataURL: blurredImage,
        placeholder: 'blur'
      })}
      onLoad={() => {
        setImageLoaded(true);
      }}
      {...nextImageProps}
    />
  );
};

Image.propTypes = {
  hasInitialAnimation: PropTypes.bool,
  lazyBoundary: PropTypes.string,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
};

Image.defaultProps = {
  hasInitialAnimation: false,
  lazyBoundary: '500px',
  priority: false,
  quality: 100,
  src: null
};

export default Image;
