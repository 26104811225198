import React from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import Button from 'components/button';
import Image from 'components/image';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const MenusCardContent = ({ callToAction, menuImages, onButtonClick }) => (
  <div className={styles.menusCardContent}>
    {Array.isArray(menuImages) && !!menuImages.length ?
      (
        <div className={styles.menusCardContent__menus}>
          {menuImages.map((menuImage, index) => (
            <div
              key={index}
              className={styles.menusCardContent__menuImageWrapper}
            >
              <Image
                alt={menuImage.alt}
                layout="fill"
                objectFit="contain"
                src={menuImage.src}
              />
            </div>
          ))}
          {(!!callToAction?.href || !!onButtonClick) && !!callToAction?.icon && (
            <Button
              className={styles.menusCardContent__gridButton}
              href={callToAction?.href}
              icon={callToAction.icon}
              outline
              textWeight="regular"
              theme="grey"
              onClick={onButtonClick}
            />
          )}
        </div>
      ) :
      (!!callToAction?.href || !!onButtonClick) && (!!callToAction?.text || !!callToAction?.icon) && (
        <Button
          href={callToAction?.href}
          icon={callToAction?.icon}
          iconPosition={callToAction?.iconPosition}
          iconSize="s"
          outline
          size="xs"
          text={callToAction?.text}
          textWeight="regular"
          theme="grey"
          onClick={onButtonClick}
        />
      )}
  </div>
);

MenusCardContent.propTypes = {
  callToAction: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired
  }),
  menuImages: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  })),
  onButtonClick: PropTypes.func
};

MenusCardContent.defaultProps = {
  callToAction: null,
  menuImages: null,
  onButtonClick: null
};

export default MenusCardContent;
